<template>
  <div>
    <!--搜索框-->
    <van-cell-group>
      <van-field
        v-model="phone"
        placeholder="客户手机号"
        :border="false"
        bind:change="onChange"
      />
      <van-field
        v-model="nickName"
        placeholder="客户名称"
        :border="false"
        bind:change="onChange"
      />
      <van-radio-group
        v-model="approveStatus"
        direction="horizontal"
        style="margin-left: 10%"
        @change="chooseIsAuth"
      >
        <van-radio :name="1">操作状态</van-radio>
        <van-radio :name="2">同意</van-radio>
        <van-radio :name="3" style="margin-left: 10%">驳回</van-radio>
      </van-radio-group>
      <sourceType @chooseSource="chooseSource" />
      <van-button type="info" size="large" @click="onload">查询</van-button>
    </van-cell-group>
    <!--加载状态-->
    <div class="loading" v-if="loading">
      <van-loading color="#1989fa" :vertical="true">加载中...</van-loading>
    </div>
    <!--数据展示-->
    <div v-else>
      <el-table
        :data="
          tableData.slice(
            (currentPage - 1) * pageCount,
            currentPage * pageCount
          )
        "
        max-height="700"
        border
        style="width: 100%"
      >
        <el-table-column prop="create_time" label="创建时间" width="100" />
        <el-table-column prop="resource_type" label="资源类型" width="100" />
        <el-table-column prop="phone" label="账号" width="100" />
        <el-table-column prop="name" label="姓名" width="100" />
        <el-table-column
          fixed="right"
          label="操作"
          width="100"
          prop="apply_status"
        >
          <template slot-scope="scope" v-if="scope.row.apply_status === ''">
            <el-button @click="approve(scope.row)" type="text" size="small"
              >审批</el-button
            >
            <el-button type="text" size="small" @click="refuse(scope.row)"
              >驳回</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="footer"
        small
        layout="prev, pager, next"
        :total="tableData.length"
        :page-count="pageCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [], //初始化数据
      loading: true, //加载状态
      pageCount: 10, //展示条数
      currentPage: 1, //当前页
      nickName: "",
      cusValue: 0, //客服id
      resourceType: 0, //资源类型
      phone: "",
      approveStatus: 1 //审批状态
    };
  },
  methods: {
    //初始化
    onload() {
      this.loading = true;
      this.tableData = [];
      this.resourceType = this.resourceType === 0 ? "" : this.resourceType;
      this.$axios
        .post(`${this.$base}/admin/resource/apply/resourceType`, {
          resourceType: this.resourceType,
          phone: this.phone,
          name: this.nickName,
          applyStatus: this.approveStatus
        })
        .then((res) => {
          res.forEach((item) => {
            item.apply_status = this.applyStatus(item.apply_status);
            item.resource_type = this.resourceData(item.resource_type);
            this.tableData.push(item);
          });
        });
      this.loading = false;
    },
    //每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    //客服组件赋值
    chooseCus(val) {
      this.cusValue = val;
    },
    //选择认证情况
    chooseIsAuth(name) {
      this.isAuth = name;
    },
    //审批
    approve(value) {
      this.$axios
        .post(`${this.$base}/admin/resource/apply/approve`, {
          ids: [value.id],
          applyStatus: "AGREE",
          approve: 58
        })
        .then((res) => {
          if (res.code === 200) {
            this.$toast("操作成功");
            this.onload();
          }
        });
    },
    //驳回
    refuse(value) {
      this.$axios
        .post(`${this.$base}/admin/resource/apply/approve`, {
          ids: [value.id],
          applyStatus: "REFUSE",
          approve: 58
        })
        .then((res) => {
          if (res.code === 200) {
            this.$toast("操作成功");
            this.onload();
          }
        });
    },
    //类型选择
    chooseSource(value) {
      this.resourceType = value;
    },
    //审批状态
    applyStatus(val) {
      switch (val) {
        case 1:
          return "";
        case 2:
          return "同意";
        case 3:
          return "驳回";
      }
    },
    resourceData(data) {
      switch (data) {
        case 1:
          return "大数据检索";
        case 2:
          return "案情法律机器人";
        case 3:
          return "日历";
        case 4:
          return "案件";
        case 6:
          data = "语音转文字";
          break;
        case 7:
          return "高级语音转文字";
        case 5:
          return "网盘";
        default:
          return "";
      }
    }
  },
  mounted() {
    this.onload();
  }
};
</script>
<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
